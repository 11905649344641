.container {
  left: px;
  right: %;
  width: 100%;
  height: auto;
  display: flex;
  overflow: auto;
  position: static;
  flex-wrap: nowrap;
  font-size: 16px;
  min-height: 100vh;
  text-align: center;
  align-items: center;
  font-family: 'Roboto';
  flex-direction: column;
  justify-content: flex-end;
  background-color: #fff;
}
.Header {
  width: 100%;
  display: inline-flex;
  flex-wrap: wrap;
  align-self: flex-start;
  align-items: center;
  padding-top: 20px;
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 20px;
  justify-content: space-evenly;
  background-color: #ffbf44;
}
.container01 {
  width: 100%;
  height: initial;
  display: flex;
  max-width: 1200px;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  justify-content: space-between;
  background-color: transparent;
}
.image {
  align-self: flex-start;
}
.container02 {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: flex-start;
  background-color: transparent;
}
.container03 {
  display: flex;
  align-self: center;
  flex-basis: auto;
  align-items: center;
  justify-content: center;
  background-color: transparent;
}
.button {
  color: #055471;
  cursor: pointer;
  display: flex;
  font-size: 16px;
  text-align: center;
  align-items: center;
  border-style: solid;
  border-width: 0;
  margin-right: 20px;
  border-radius: 8px;
  justify-content: center;
  background-color: transparent;
}
.button01 {
  color: #055471;
  cursor: pointer;
  display: flex;
  font-size: 16px;
  text-align: center;
  align-items: center;
  border-style: solid;
  border-width: 0;
  margin-right: 20px;
  border-radius: 8px;
  justify-content: center;
  background-color: transparent;
}
.button02 {
  color: #055471;
  cursor: pointer;
  display: flex;
  font-size: 16px;
  text-align: center;
  align-items: center;
  border-style: solid;
  border-width: 0;
  margin-right: 20px;
  border-radius: 8px;
  justify-content: center;
  background-color: transparent;
}
.button03 {
  color: #ffbf44;
  cursor: pointer;
  display: flex;
  font-size: 16px;
  text-align: center;
  align-items: center;
  font-weight: bold;
  padding-top: 15px;
  border-style: solid;
  border-width: 0;
  padding-left: 20px;
  border-radius: 8px;
  padding-right: 20px;
  padding-bottom: 15px;
  justify-content: center;
  background-color: #055471;
}
.hamburgerButton {
  color: #000;
  display: none;
  font-size: 13px;
  padding-top: 5px;
  border-color: #0089be;
  border-style: solid;
  border-width: 1px;
  padding-left: 10px;
  border-radius: 4px;
  padding-right: 10px;
  padding-bottom: 5px;
  background-color: #fff;
}
.Hamburger {
  display: none;
}
.hamburgerMenu {
  top: 160px;
  width: 100%;
  display: none;
  position: absolute;
  justify-content: center;
  background-color: #055471;
}
.container04 {
  width: 100%;
  display: none;
  max-width: 1200px;
  justify-content: space-between;
  background-color: transparent;
}
.Stay-in-touch {
  height: 30%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  background-color: transparent;
}
.LinkedIn {
  cursor: pointer;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.icon {
  cursor: pointer;
  position: absolute;
}
.link {
  color: #ffbf44;
  cursor: pointer;
  display: block;
  z-index: 10;
  padding-left: 30px;
  text-decoration: none;
}
.GitLab {
  cursor: pointer;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: flex-start;
  background-color: transparent;
}
.icon01 {
  cursor: pointer;
  position: absolute;
}
.link01 {
  color: #ffbf44;
  cursor: pointer;
  display: block;
  z-index: 10;
  padding-left: 30px;
  text-decoration: none;
}
.container05 {
  height: 70%;
  display: flex;
  align-self: center;
  flex-basis: auto;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  background-color: transparent;
}
.button04 {
  color: #ffbf44;
  cursor: pointer;
  height: 23%;
  display: flex;
  font-size: 16px;
  text-align: center;
  align-items: center;
  border-style: solid;
  border-width: 0;
  margin-right: 20px;
  border-radius: 8px;
  justify-content: center;
  background-color: transparent;
}
.button05 {
  color: #ffbf44;
  cursor: pointer;
  height: 23%;
  display: flex;
  font-size: 16px;
  text-align: center;
  align-items: center;
  border-style: solid;
  border-width: 0;
  margin-right: 20px;
  border-radius: 8px;
  justify-content: center;
  background-color: transparent;
}
.button06 {
  color: #ffbf44;
  cursor: pointer;
  height: 23%;
  display: flex;
  font-size: 16px;
  text-align: center;
  align-items: center;
  border-style: solid;
  border-width: 0;
  margin-right: 30px;
  border-radius: 8px;
  justify-content: center;
  background-color: transparent;
}
.button07 {
  color: #055471;
  cursor: pointer;
  height: 23%;
  display: flex;
  font-size: 16px;
  text-align: center;
  align-items: center;
  border-style: solid;
  border-width: 0;
  border-radius: 8px;
  justify-content: center;
  background-color: #ffbf44;
}
.Intro {
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  align-items: flex-end;
  flex-direction: row;
  background-size: cover;
  justify-content: center;
  background-color: #055471;
  background-image: url("./playground_assets/hero-1000h.jpeg");
  background-repeat: no-repeat;
  background-position: center;
}
.container06 {
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: rgba(20, 20, 20, 0.8);
}
.container07 {
  width: 100%;
  display: flex;
  z-index: 10;
  max-width: 1200px;
  min-height: 600px;
  align-items: flex-start;
  padding-top: 240px;
  padding-left: 20px;
  padding-right: 20px;
  flex-direction: column;
  padding-bottom: 80px;
  justify-content: flex-end;
  background-color: transparent;
}
.text {
  color: #fff;
  cursor: move;
  margin: 0;
  opacity: 1;
  font-size: 30px;
  text-align: center;
  line-height: 1.5;
  text-transform: uppercase;
}
.text01 {
  color: #fff;
  font-size: 24px;
  margin-top: 0.83em;
  text-align: center;
  margin-bottom: 0.83em;
}
.container08 {
  display: flex;
  flex-basis: auto;
  background-color: transparent;
}
.button08 {
  color: #055471;
  cursor: pointer;
  display: inline-block;
  font-size: 16px;
  margin-top: 40px;
  text-align: center;
  font-weight: bold;
  padding-top: 15px;
  border-style: solid;
  border-width: 0;
  padding-left: 20px;
  border-radius: 8px;
  padding-right: 20px;
  padding-bottom: 15px;
  background-color: #ffbf44;
}
.Services {
  width: 100%;
  display: flex;
  margin-top: 100px;
  align-items: center;
  padding-top: 40px;
  padding-left: 20px;
  margin-bottom: 0;
  padding-right: 20px;
  flex-direction: column;
  padding-bottom: 140px;
  justify-content: flex-start;
  background-color: transparent;
}
.Heading {
  width: 100%;
  height: 10%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
}
.text02 {
  color: #055471;
  display: flex;
  font-size: 3em;
  margin-top: 0;
  text-align: center;
  align-items: center;
  font-family: inherit;
  font-weight: normal;
  justify-content: center;
}
.Section1 {
  width: 100%;
  display: flex;
  max-width: 1200px;
  align-items: stretch;
  flex-direction: row;
  justify-content: center;
  background-color: transparent;
}
.IT-Support {
  width: 100%;
  display: flex;
  margin-top: 20px;
  min-height: 334px;
  align-items: center;
  padding-top: 20px;
  border-color: rgba(5, 84, 113, 0.25);
  border-style: solid;
  border-width: 5px;
  margin-right: 20px;
  padding-left: 20px;
  border-radius: 20px;
  margin-bottom: 20px;
  padding-right: 20px;
  flex-direction: column;
  padding-bottom: 20px;
  justify-content: flex-start;
  background-color: transparent;
}
.container09 {
  display: flex;
  min-height: 200px;
  align-items: center;
  flex-direction: column;
  background-color: transparent;
}
.text03 {
  color: #055471;
  display: flex;
  font-size: 1.75em;
  text-align: center;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}
.text04 {
  color: #055471;
  font-size: 1em;
  text-align: center;
  font-weight: bold;
  line-height: 1.5;
}
.IT-Support1 {
  width: 100%;
  margin: 20px;
  display: flex;
  min-height: 334px;
  align-items: center;
  padding-top: 20px;
  border-color: rgba(5, 84, 113, 0.25);
  border-style: solid;
  border-width: 5px;
  padding-left: 20px;
  border-radius: 20px;
  padding-right: 20px;
  flex-direction: column;
  padding-bottom: 20px;
  justify-content: flex-start;
  background-color: transparent;
}
.container10 {
  display: flex;
  min-height: 200px;
  align-items: center;
  flex-direction: column;
  background-color: transparent;
}
.text05 {
  color: #055471;
  display: flex;
  font-size: 1.75em;
  text-align: center;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}
.text06 {
  color: #055471;
  font-size: 1em;
  text-align: center;
  font-weight: bold;
  line-height: 1.5;
}
.IT-Support2 {
  width: 100%;
  display: flex;
  margin-top: 20px;
  min-height: 334px;
  align-items: center;
  margin-left: 20px;
  padding-top: 20px;
  border-color: rgba(5, 84, 113, 0.25);
  border-style: solid;
  border-width: 5px;
  padding-left: 20px;
  border-radius: 20px;
  margin-bottom: 20px;
  padding-right: 20px;
  flex-direction: column;
  padding-bottom: 20px;
  justify-content: flex-start;
  background-color: transparent;
}
.container11 {
  display: flex;
  min-height: 200px;
  align-items: center;
  flex-direction: column;
  background-color: transparent;
}
.text07 {
  color: #055471;
  display: flex;
  font-size: 1.75em;
  text-align: center;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}
.text08 {
  color: #055471;
  font-size: 1em;
  text-align: center;
  font-weight: bold;
  line-height: 1.5;
}
.Section11 {
  width: 100%;
  display: flex;
  max-width: 1200px;
  align-items: stretch;
  flex-direction: row;
  justify-content: center;
  background-color: transparent;
}
.IT-Support3 {
  width: 100%;
  display: flex;
  margin-top: 20px;
  align-items: center;
  padding-top: 20px;
  border-color: rgba(5, 84, 113, 0.25);
  border-style: solid;
  border-width: 5px;
  margin-right: 20px;
  padding-left: 20px;
  border-radius: 20px;
  padding-right: 20px;
  flex-direction: column;
  padding-bottom: 20px;
  justify-content: flex-start;
  background-color: transparent;
}
.container12 {
  display: flex;
  min-height: 200px;
  align-items: center;
  flex-direction: column;
  background-color: transparent;
}
.text09 {
  color: #055471;
  display: flex;
  font-size: 1.75em;
  text-align: center;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}
.text10 {
  color: #055471;
  font-size: 1em;
  text-align: center;
  font-weight: bold;
  line-height: 1.5;
}
.IT-Support4 {
  width: 100%;
  display: flex;
  margin-top: 20px;
  align-items: center;
  margin-left: 20px;
  padding-top: 20px;
  border-color: rgba(5, 84, 113, 0.25);
  border-style: solid;
  border-width: 5px;
  margin-right: 20px;
  padding-left: 20px;
  border-radius: 20px;
  padding-right: 20px;
  flex-direction: column;
  padding-bottom: 20px;
  justify-content: center;
  background-color: transparent;
}
.container13 {
  display: flex;
  min-height: 200px;
  align-items: center;
  flex-direction: column;
  background-color: transparent;
}
.text11 {
  color: #055471;
  display: flex;
  font-size: 1.75em;
  text-align: center;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}
.text12 {
  color: #055471;
  font-size: 1em;
  text-align: center;
  font-weight: bold;
  line-height: 1.5;
}
.IT-Support5 {
  width: 100%;
  display: flex;
  margin-top: 20px;
  align-items: center;
  margin-left: 20px;
  padding-top: 20px;
  border-color: rgba(5, 84, 113, 0.25);
  border-style: solid;
  border-width: 5px;
  padding-left: 20px;
  border-radius: 20px;
  padding-right: 20px;
  flex-direction: column;
  padding-bottom: 20px;
  justify-content: center;
  background-color: transparent;
}
.container14 {
  display: flex;
  min-height: 200px;
  align-items: center;
  flex-direction: column;
  background-color: transparent;
}
.text13 {
  color: #055471;
  display: flex;
  font-size: 1.75em;
  text-align: center;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}
.text14 {
  color: #055471;
  font-size: 1em;
  text-align: center;
  font-weight: bold;
  line-height: 1.5;
}
.About-Us {
  width: 100%;
  display: flex;
  grid-gap: 10px;
  align-items: center;
  margin-left: 0;
  padding-top: 140px;
  margin-right: 0;
  align-content: stretch;
  justify-items: stretch;
  flex-direction: column;
  padding-bottom: 140px;
  justify-content: stretch;
  background-color: #ffbf44;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr;
}
.text15 {
  color: #055471;
  display: flex;
  font-size: 3em;
  margin-top: 0;
  text-align: center;
  align-items: center;
  font-weight: normal;
  padding-left: 0;
  margin-bottom: 40px;
  padding-right: 0;
  padding-bottom: 20px;
  justify-content: center;
}
.container15 {
  width: 100%;
  display: flex;
  max-width: 1200px;
  align-items: flex-start;
  justify-content: space-evenly;
  background-color: transparent;
}
.div {
  width: 100%;
  display: flex;
  max-width: 500px;
  align-items: center;
  flex-direction: column;
  background-color: transparent;
}
.link02 {
  display: contents;
}
.image02 {
  width: 200px;
  height: 200px;
  border-color: #055471;
  border-radius: 50%;
  margin-bottom: 20px;
}
.text16 {
  color: #055471;
  font-size: 1.75em;
  margin-bottom: 20px;
}
.container16 {
  display: block;
  align-items: flex-start;
  margin-bottom: 20px;
  background-color: transparent;
}
.text17 {
  color: #055471;
  font-weight: bold;
  line-height: 1.5;
}
.text18 {
  color: #055471;
  line-height: 1.5;
}
.text19 {
  color: #055471;
  font-weight: bold;
  line-height: 1.8;
}
.container17 {
  width: 100%;
  display: flex;
  max-width: 500px;
  align-items: center;
  flex-direction: column;
  background-color: transparent;
}
.link03 {
  display: contents;
}
.image03 {
  height: 200px;
  border-radius: 50%;
  margin-bottom: 20px;
}
.text20 {
  color: #055471;
  font-size: 1.75em;
  margin-bottom: 20px;
}
.container18 {
  display: block;
  align-items: flex-start;
  margin-bottom: 20px;
  background-color: transparent;
}
.text21 {
  color: #055471;
  font-weight: bold;
  line-height: 1.5;
}
.text22 {
  color: #055471;
  line-height: 1.5;
}
.text23 {
  color: #055471;
  font-weight: bold;
  line-height: 1.8;
}
.container19 {
  width: 100%;
  display: inline-block;
  grid-gap: 10px;
  align-items: center;
  align-content: center;
  justify-items: center;
  justify-content: center;
  background-color: transparent;
  grid-template-rows: 2 auto;
  grid-template-columns: 1fr 1fr;
}
.Certifications {
  width: 100%;
  height: 40%;
  display: flex;
  align-items: center;
  padding-top: 140px;
  padding-left: 20px;
  padding-right: 20px;
  flex-direction: column;
  padding-bottom: 140px;
  justify-content: center;
  background-color: transparent;
}
.text24 {
  color: #055471;
  display: flex;
  font-size: 4em;
  margin-top: 0;
  text-align: center;
  align-items: center;
  font-weight: normal;
  padding-left: 0;
  margin-bottom: 0;
  padding-right: 0;
  justify-content: center;
}
.container20 {
  width: 100%;
  display: flex;
  max-width: 1200px;
  justify-content: center;
  background-color: transparent;
}
.CKAD {
  width: 250px;
  height: 250px;
  margin: 20px;
  display: flex;
  align-items: center;
  padding-top: 20px;
  border-width: 5px;
  padding-left: 20px;
  border-radius: 20px;
  padding-right: 20px;
  flex-direction: column;
  padding-bottom: 5px;
  background-size: contain;
  justify-content: flex-end;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center;
}
.link04 {
  display: contents;
}
.image04 {
  width: 230px;
  height: 230px;
  align-self: center;
}
.DevOpsPro {
  width: 250px;
  height: 250px;
  margin: 20px;
  display: flex;
  align-items: center;
  padding-top: 20px;
  border-width: 5px;
  padding-left: 20px;
  border-radius: 20px;
  padding-right: 20px;
  flex-direction: column;
  padding-bottom: 5px;
  background-size: contain;
  justify-content: flex-end;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center;
}
.link05 {
  display: contents;
}
.image05 {
  width: 230px;
  height: 230px;
  align-self: center;
}
.ArchPro {
  width: 250px;
  height: 250px;
  margin: 20px;
  display: flex;
  align-items: center;
  padding-top: 20px;
  border-width: 5px;
  padding-left: 20px;
  border-radius: 20px;
  padding-right: 20px;
  flex-direction: column;
  padding-bottom: 5px;
  background-size: contain;
  justify-content: flex-end;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center;
}
.link06 {
  display: contents;
}
.image06 {
  width: 230px;
  height: 230px;
  object-fit: cover;
}
.container21 {
  width: 100%;
  display: flex;
  max-width: 1200px;
  justify-content: center;
  background-color: transparent;
}
.Architect {
  width: 250px;
  height: 250px;
  margin: 20px;
  display: flex;
  align-items: center;
  padding-top: 20px;
  border-width: 5px;
  padding-left: 20px;
  border-radius: 20px;
  padding-right: 20px;
  flex-direction: column;
  padding-bottom: 5px;
  background-size: contain;
  justify-content: flex-end;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center;
}
.image07 {
  width: 230px;
  height: 230px;
  align-self: center;
}
.link07 {
  display: contents;
}
.SysOps {
  width: 250px;
  height: 250px;
  margin: 20px;
  display: flex;
  align-items: center;
  padding-top: 20px;
  border-width: 5px;
  padding-left: 20px;
  border-radius: 20px;
  padding-right: 20px;
  flex-direction: column;
  padding-bottom: 5px;
  background-size: contain;
  justify-content: flex-end;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center;
}
.image08 {
  width: 230px;
  height: 230px;
  align-self: center;
}
.link08 {
  display: contents;
}
.Developer {
  width: 250px;
  height: 250px;
  margin: 20px;
  display: flex;
  align-items: center;
  padding-top: 20px;
  border-width: 5px;
  padding-left: 20px;
  border-radius: 20px;
  padding-right: 20px;
  flex-direction: column;
  padding-bottom: 5px;
  background-size: contain;
  justify-content: flex-end;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center;
}
.image09 {
  width: 230px;
  height: 230px;
  align-self: center;
}
.container22 {
  width: 100%;
  display: flex;
  max-width: 1200px;
  justify-content: center;
  background-color: transparent;
}
.Practitioner {
  width: 250px;
  height: 250px;
  margin: 20px;
  display: flex;
  align-items: center;
  padding-top: 20px;
  border-width: 5px;
  padding-left: 20px;
  border-radius: 20px;
  padding-right: 20px;
  flex-direction: column;
  padding-bottom: 5px;
  background-size: contain;
  justify-content: flex-end;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center;
}
.link09 {
  display: contents;
}
.image10 {
  width: 230px;
  height: 230px;
  align-self: center;
}
.AzurePractitioner {
  width: 250px;
  height: 250px;
  margin: 20px;
  display: flex;
  position: relative;
  align-items: center;
  padding-top: 20px;
  border-width: 5px;
  padding-left: 20px;
  border-radius: 20px;
  padding-right: 20px;
  flex-direction: column;
  padding-bottom: 5px;
  background-size: contain;
  justify-content: center;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center;
}
.link10 {
  display: contents;
}
.image11 {
  flex: 1;
  width: 230px;
  bottom: 1.2px;
  height: 230px;
  margin: auto;
  position: absolute;
  object-fit: cover;
  text-decoration: none;
}
.Linux {
  width: 250px;
  height: 250px;
  margin: 20px;
  display: flex;
  align-items: center;
  padding-top: 20px;
  border-width: 5px;
  padding-left: 20px;
  border-radius: 20px;
  padding-right: 20px;
  flex-direction: column;
  background-size: contain;
  justify-content: flex-end;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center;
}
.link11 {
  display: contents;
}
.image12 {
  width: 230px;
  height: 230px;
}
.Cisco {
  width: 250px;
  height: 250px;
  margin: 20px;
  display: flex;
  align-items: center;
  padding-top: 5px;
  border-width: 5px;
  padding-left: 20px;
  border-radius: 20px;
  padding-right: 20px;
  flex-direction: column;
  padding-bottom: 0;
  background-size: contain;
  justify-content: space-around;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: contain;
}
.link12 {
  display: contents;
}
.container23 {
  width: 220px;
  height: 220px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.image13 {
  width: 100%;
}
.text26 {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-end;
}
.text27 {
  color: #055471;
  font-size: 24px;
  margin-top: 80px;
  font-weight: bold;
}
.button09 {
  color: #055471;
  cursor: pointer;
  display: inline-block;
  font-size: 16px;
  margin-top: 40px;
  text-align: center;
  font-weight: bold;
  padding-top: 15px;
  border-style: solid;
  border-width: 0;
  padding-left: 20px;
  border-radius: 8px;
  padding-right: 20px;
  padding-bottom: 15px;
  background-color: #ffbf44;
}
.Footer {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  padding-upper: 50px;
  flex-direction: row;
  padding-bottom: 20px;
  justify-content: space-around;
  background-color: #055471;
}
.container24 {
  width: 100%;
  display: flex;
  max-width: 1200px;
  align-items: flex-start;
  padding-left: 20px;
  padding-right: 20px;
  justify-content: space-evenly;
  background-color: transparent;
}
.Stay-in-touch1 {
  height: 95%;
  display: flex;
  align-items: flex-start;
  padding-top: 20px;
  flex-direction: column;
  padding-bottom: 20px;
  justify-content: center;
  background-color: transparent;
}
.heading3 {
  color: #ffbf44;
  display: flex;
  font-size: 1.17em;
  margin-top: 20px;
  align-items: center;
  margin-bottom: 20px;
  justify-content: flex-start;
}
.LinkedIn1 {
  width: 100%;
  cursor: pointer;
  display: flex;
  position: relative;
  align-items: center;
  margin-bottom: 10px;
  justify-content: flex-start;
  background-color: transparent;
}
.icon08 {
  cursor: pointer;
  position: absolute;
}
.link13 {
  color: #ffbf44;
  cursor: pointer;
  display: block;
  z-index: 10;
  padding-left: 30px;
  text-decoration: none;
}
.GitLab1 {
  cursor: pointer;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: flex-start;
  background-color: transparent;
}
.icon09 {
  cursor: pointer;
  position: absolute;
}
.link14 {
  color: #ffbf44;
  cursor: pointer;
  display: block;
  z-index: 10;
  padding-left: 30px;
  text-decoration: none;
}
.Copyright {
  height: 100%;
  display: flex;
  align-self: center;
  align-items: center;
  padding-top: 20px;
  flex-direction: column;
  padding-bottom: 20px;
  justify-content: center;
  background-color: transparent;
}
.text28 {
  color: #ffbf44;
  display: flex;
  font-size: 16px;
  margin-top: 1em;
  align-items: center;
  font-weight: normal;
  margin-bottom: 0;
  flex-direction: column;
  justify-content: center;
}
.text29 {
  color: #ffbf44;
  display: flex;
  font-size: 16px;
  margin-top: 10px;
  align-items: center;
  font-weight: normal;
  margin-bottom: 1em;
  flex-direction: column;
  justify-content: center;
}
.container25 {
  display: flex;
  align-self: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: transparent;
}
.image14 {
  fill: yellow;
  cursor: pointer;
  display: block;
}
.container26 {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
}
@media(max-width: 991px) {
  .Header {
    align-self: flex-start;
    padding-top: 20;
    padding-left: 5px;
    padding-right: 5px;
    padding-bottom: 20;
    justify-content: space-around;
  }
  .image {
    width: 80;
    height: 80;
  }
  .Hamburger {
    width: 30px;
    cursor: pointer;
    height: 30px;
    display: none;
    position: static;
    align-items: center;
    justify-content: center;
    background-color: transparent;
  }
  .container07 {
    width: 100%;
    max-width: 1200px;
    min-height: auto;
    align-items: center;
    padding-top: 120px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 80px;
  }
  .Section1 {
    flex-wrap: nowrap;
    align-items: center;
    flex-direction: column;
  }
  .IT-Support {
    width: 100%;
    max-width: 900px;
    margin-top: 20px;
    min-height: 0;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 20px;
    justify-content: center;
  }
  .container09 {
    min-height: 0;
  }
  .text04 {
    max-width: 400px;
  }
  .IT-Support1 {
    width: 100%;
    max-width: 900px;
    margin-top: 20px;
    min-height: 0;
    margin-left: 20px;
    padding-top: 20px;
    margin-right: 20px;
    margin-bottom: 20px;
    padding-bottom: 20px;
    justify-content: center;
  }
  .container10 {
    min-height: 0;
  }
  .icon03 {
    width: 30;
    height: 30;
  }
  .text06 {
    max-width: 400;
  }
  .IT-Support2 {
    width: 100%;
    max-width: 900px;
    min-height: 0;
    margin-left: 20px;
    margin-right: 20px;
    justify-content: center;
  }
  .container11 {
    min-height: 0;
  }
  .text07 {
    max-width: 400;
  }
  .text08 {
    max-width: 400;
  }
  .Section11 {
    flex-wrap: nowrap;
    align-items: center;
    flex-direction: column;
  }
  .IT-Support3 {
    width: 100%;
    max-width: 900px;
    margin-top: 20px;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 20px;
    justify-content: flex-start;
  }
  .container12 {
    min-height: 0;
  }
  .text09 {
    max-width: 400;
  }
  .text10 {
    max-width: 400;
  }
  .IT-Support4 {
    width: 100%;
    max-width: 900px;
    margin-top: 20px;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 20px;
  }
  .container13 {
    min-height: 0;
  }
  .text12 {
    max-width: 400;
  }
  .IT-Support5 {
    width: 100%;
    max-width: 900px;
    margin-left: 20px;
    margin-right: 20px;
    flex-direction: column;
    justify-content: flex-start;
  }
  .container14 {
    min-height: 0;
  }
  .text14 {
    max-width: 400;
  }
  .container15 {
    align-items: center;
    flex-direction: column;
  }
  .container17 {
    margin-top: 80px;
  }
  .container20 {
    width: 100%;
    flex-wrap: nowrap;
    max-width: 1200px;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
  }
  .container21 {
    width: 100%;
    flex-wrap: nowrap;
    max-width: 1200px;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
  }
  .container22 {
    width: 100%;
    flex-wrap: nowrap;
    max-width: 1200px;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
  }
}
@media(max-width: 767px) {
  .image {
    width: 80;
    height: 80;
  }
  .container02 {
    display: none;
  }
  .hamburgerButton {
    color: #055471;
    display: none;
    font-size: 24px;
    border-color: transparent;
    background-color: transparent;
  }
  .Hamburger {
    display: flex;
    z-index: 10;
  }
  .hamburgerMenu {
    width: 100%;
    z-index: 10;
    justify-content: center;
    background-color: #055471;
  }
  .container04 {
    display: flex;
    align-items: center;
    padding-top: 40px;
    flex-direction: column-reverse;
    padding-bottom: 40px;
  }
  .Stay-in-touch {
    padding-top: 40px;
    flex-direction: row;
  }
  .LinkedIn {
    margin-right: 20px;
  }
  .button04 {
    color: #ffbf44;
  }
  .button05 {
    color: #ffbf44;
  }
  .button06 {
    color: #ffbf44;
    margin-right: 20px;
  }
  .button07 {
    color: #ffbf44;
    background-color: transparent;
  }
  .Intro {
    width: 100%;
    align-items: center;
    justify-content: center;
  }
  .container07 {
    z-index: 5;
  }
  .IT-Support {
    width: 100%;
  }
  .icon02 {
    width: 30;
  }
  .IT-Support1 {
    width: 100%;
  }
  .IT-Support2 {
    width: 100%;
    max-width: 900px;
    min-height: 0;
  }
  .IT-Support3 {
    width: 100%;
    max-width: 900px;
    min-height: 0;
  }
  .IT-Support4 {
    width: 100%;
    max-width: 900px;
    min-height: 0;
  }
  .IT-Support5 {
    width: 100%;
    max-width: 900px;
    min-height: 0;
  }
  .Certifications {
    width: 100%;
    height: 40%;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
  .container20 {
    width: 100%;
    flex-wrap: nowrap;
    max-width: 1200px;
    max-height: 1200px;
    allign-items: center;
    flex-direction: column;
    justify-content: space-betweem;
  }
  .container21 {
    width: 100%;
    flex-wrap: nowrap;
    max-width: 1200px;
    max-height: 1200px;
    allign-items: center;
    flex-direction: column;
    justify-content: space-betweem;
  }
  .SysOps {
    width: 250px;
  }
  .Developer {
    width: 250px;
  }
  .container22 {
    width: 100%;
    flex-wrap: nowrap;
    max-width: 1200px;
    max-height: 1200px;
    allign-items: center;
    flex-direction: column;
    justify-content: space-betweem;
  }
  .container24 {
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
  .Stay-in-touch1 {
    align-items: center;
  }
  .text28 {
    margin-top: 0;
    text-align: center;
    margin-bottom: 10px;
  }
  .text29 {
    margin-top: 0;
    text-align: center;
    margin-bottom: 20px;
  }
}
@media(max-width: 479px) {
  .container {
    position: relative;
  }
  .Header {
    padding-top: 10px;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 10px;
  }
  .container02 {
    display: none;
  }
  .hamburgerButton {
    display: none;
  }
  .hamburgerMenu {
    z-index: 10;
  }
  .container04 {
    box-shadow: 0px 5px 10px 0px #1a1a1a;
  }
  .Stay-in-touch {
    padding-top: 40px;
  }
  .container05 {
    flex-direction: column;
  }
  .button04 {
    margin-right: 0;
    margin-bottom: 20px;
  }
  .button05 {
    margin-right: 0;
    margin-bottom: 20px;
  }
  .button06 {
    margin-right: 0;
    margin-bottom: 20px;
  }
  .button07 {
    color: #ffbf44;
    background-color: transparent;
  }
  .container07 {
    z-index: 9;
    padding-top: 40px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 80px;
  }
  .text {
    margin-top: 80;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 0;
  }
  .container08 {
    width: 100%;
  }
  .button08 {
    width: 100%;
  }
  .Services {
    margin-top: 0;
    padding-top: 40px;
    padding-left: 20px;
    margin-bottom: 0;
    padding-right: 20px;
    padding-bottom: 80px;
  }
  .text02 {
    font-size: 3em;
  }
  .About-Us {
    padding-top: 40px;
    padding-bottom: 80px;
  }
  .text15 {
    font-size: 3em;
    padding-top: 0;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 20px;
  }
  .div {
    padding-left: 20px;
    padding-right: 20px;
  }
  .container17 {
    margin-top: 80px;
    padding-left: 20px;
    padding-right: 20px;
  }
  .Certifications {
    padding-top: 40px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 80px;
  }
  .text24 {
    font-size: 3em;
  }
  .container20 {
    align-items: center;
  }
  .container21 {
    align-items: center;
  }
  .container22 {
    align-items: center;
  }
  .Cisco {
    justify-content: space-around;
  }
  .button09 {
    width: 100%;
  }
  .heading3 {
    display: none;
  }
  .Copyright {
    padding-top: 0;
    padding-bottom: 20px;
  }
  .text28 {
    margin-top: 1em;
    margin-bottom: 0;
  }
  .text29 {
    margin-top: 0;
    margin-bottom: 1em;
  }
}
